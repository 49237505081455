import { get, post, put, Delete } from "../axios";

// 产品列表-分类列表（用于筛选，新增和编辑）
export const infoSolutionClassify = (params) =>
  get("/web/info/product/classify/list", params);

// 产品列表-列表
export const infoProductList = (params) =>
  get("/web/info/product/list", params);

// 产品列表-新增
export const infoProductAdd = (params) => post("/web/info/product/add", params);

// 产品列表-编辑
export const infoProductUpdate = (params) =>
  put("/web/info/product/update", params);

// 产品列表-删除
export const infoProductDelete = (id) =>
  Delete("/web/info/product/delete/" + id, "");

// 产品列表-详情
export const infoProductView = (id) => get("/web/info/product/view/" + id, "");

// 产品列表-首页推荐-状态编辑
export const infoProductHomeUpdate = (params) =>
  put("/web/info/product/home/update", params);
